import { getalllibrary, deleteibrary, addibrary } from '@/libraryapi/library'

export default {
    namespaced:true,    //namespaced:true代表该模块带有独立命名空间
		state:{        //否则，默认是处于全局命名空间，就和非模块化一样
			alllibrary:[]
    },
    mutations:{
			GETLIBRARY(state:any,alllibrary:any){
				state.alllibrary=alllibrary
			}
    },
    actions:{
			// 获取图书馆数据
			async getalllibrary1(context:any){
				let result:any =await getalllibrary()
				// console.log(result);
				if(result?.code==0){
					context.commit("GETLIBRARY",result.data.Data)
					return 'ok'
				}else{
					return Promise.reject("获取图书馆失败")
				}	
			},
			// 删除图书馆数据
			async deleteibrary1(context:any,id:any){
				let result:any =await deleteibrary(id)
				// console.log(result);
				if(result?.code==0){	
					return 'ok'
				}else{
					return Promise.reject("删除图书馆失败")
				}	
			},
			// 添加图书馆数据
			async addibrary1(context:any,nameaddr:any){
				// console.log(nameaddr);
				
				let result:any =await addibrary(nameaddr)
				// console.log(result);
				if(result?.code==0){
					
					return 'ok'
				}else{
					return Promise.reject("添加图书馆失败")
				}	
			},
    },
}
