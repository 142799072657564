import { ElMessage } from 'element-plus'
import axios from 'axios'
// const baseURL  =  'http://middle.icampus.ltd'
// 床架axios实例
const requests = axios.create({
	// baseURL: '/zhongtaiapi',
	baseURL: 'https://api.icampus.ltd',
	timeout: 5000,
	headers: {
		"Content-type": "application/json;charset=utf-8"
	},
	// 设置这个就能请求接口时携带cookie
	withCredentials: true
})
// axios.defaults.withCredentials = true
//请求拦截(拦截下来接口)-->给权限接口携带cookies
requests.interceptors.request.use((config) => {
	// 给config.headers对象定义	
	config.headers = config.headers || {}
	// session_id
	// config.headers['set-cookie']='iCampus-Platform-Manage-Token=1v937i97oe9on0cqv44e6bm3rd400hh6; Path=/; Expires=Thu, 02 Mar 2023 14:22:32 GMT'
	// 先判断是否本地存储了cookies
	// if(localStorage.getItem("cookies")){
	//     config.headers.cookies = localStorage.getItem("cookies") || ""
	// }
	// 一定要return-->不然请求不了接口
	return config
})

//响应拦截-->code=200成功
requests.interceptors.response.use((res: any) => {
	// 获取code,number是类型定义
	const code = res.data.code

	// 请求接口的时候可能会出现服务器等等报错原因之类的,就直接退出 通过removeItem就能
	// 够控制router路由实现跳转 (并提示信息)
	if (code == -421 || code == -423 || code == -410 || code == -1011) {
		// 接口返回错误信息-->发送请求失败(返回错误信息)	
		localStorage.removeItem("cookies")
		localStorage.removeItem("type")
		ElMessage.error(res.data.message)
		return Promise.reject(res.data)
	} else if (code == 0) {
		// 成功 信息返回出去
		return res.data
	}

}, (err) => {
	// 错误信息
	console.log(err)
})


export default requests

