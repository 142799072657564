import requests from "@/request/collaborateindex";

// 登录 https://api.icampus.ltd/api/cloud/base/login
// export const login1 = (data: any) => requests({ url: "api/cloud/partner/login", method: "POST", data })
export const login1 = (data: any) => requests({ url: "api/cloud/base/login", method: "POST", data })

// 注册 https://api.icampus.ltd/api/cloud/base/register
export const register = (data: any) => requests({ url: "api/cloud/base/register", method: "POST", data })

// 发送验证码 https://api.icampus.ltd/api/cloud/base/code
export const getcode = (data: any) => requests({ url: "api/cloud/base/code", method: "POST", data })

// 获取新token https://api.icampus.ltd/api/cloud/base/renew
export const getrenew = (data: any) => requests({ url: "api/cloud/base/renew", method: "POST", data })