
import { FormInstance, FormRules, ElMessage } from 'element-plus'
import { defineComponent, ref, reactive, toRefs, onMounted, watch, computed } from 'vue'

import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { isShow } from '../isShow'
export default defineComponent({
	setup() {
		// 注册ref实例
		const formRef = ref<FormInstance>()
		const store = useStore()
		const banner12 = require('@/assets/banner12.png')
		const state = reactive<{ circleUrl: string; routesmatch: any; form: any; rules: FormRules; login: any }>({
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
			// 存储当前路由match匹配规则 最终用来展示里面meta的title信息 （面包屑）
			routesmatch: [],
			form: {
				password1: '',
				password2: ''
			},
			// 定义表单定义规则
			rules: {
				// blur失去光标
				password1: [{ required: true, message: '旧密码不能为空', trigger: ['blur', 'change'] }],
				password2: [
					{ required: true, message: '请输入新密码', trigger: ['blur', 'change'] },
					{ pattern: /^\d{8}$/, message: '新密码必须是8位数字', trigger: ['blur', 'change'] }
				]
			},
			// 登录之后存储的账号+密码
			login: {}
		})
		const route = useRoute()
		const router = useRouter()
		// 弹出框弹出
		const visible = ref(false)
		const formLabelWidth = '150px'
		watch(
			route,
			() => {
				// 面包屑需要遍历每个路由下match(匹配规则)里面meta(元信息)的title
				let title: any = []
				router.currentRoute.value.matched.forEach((item) => {
					// console.log(item.meta)
					title.push(item.meta)
				})
				state.routesmatch.length = 0
				state.routesmatch.push(...title)
			},
			{ deep: true, immediate: true }
		)
		onMounted(() => {
			if (localStorage.getItem('login')) {
				state.login = JSON.parse(localStorage.getItem('login') || '')
			}
		})
		const tuichu = () => {
			store.dispatch('libraryuser/loginout')
			router.push('/login')
		}
		const changeIshow = () => {
			isShow.value = !isShow.value
		}
		const changepassword = () => {
			// console.log('修改密码')
			state.form.password1 = ''
			state.form.password2 = ''
			visible.value = true
		}
		// 点击”确定“ 修改密码
		const checkgood = async () => {
			await formRef.value?.validate().catch((err) => {
				ElMessage({
					showClose: true,
					message: '查看新密码是否输入规范',
					type: 'error'
				})
				// 阻拦往下执行
				throw err
			})
			store
				.dispatch('libraryuser/changepassword1', { password1: state.form.password1, password2: state.form.password2 })
				.then((res) => {
					ElMessage({
						showClose: true,
						message: '修改密码成功',
						type: 'success'
					})
					visible.value = false
				})
				.catch((err) => {
					ElMessage({
						showClose: true,
						message: err.message,
						type: 'error'
					})
				})
		}
		const checkpoor = () => {
			visible.value = false
		}
		return { isShow, ...toRefs(state), tuichu, banner12, route, changeIshow, changepassword, visible, formLabelWidth, formRef, checkgood, checkpoor }
	}
})
