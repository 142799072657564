
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
// 状态共用,放在同一个组件或一个文件中
import { isShow } from '../../isShow'
export default defineComponent({
	name: 'App',
	props: {
		allRoute: {
			type: Array
		}
	},
	setup(props) {
		onMounted(() => {})
		return {
			isShow
		}
	}
})
