
import { defineComponent, reactive, toRefs, onMounted, watch } from 'vue'

import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

// 状态共用,放在同一个组件或一个文件中
import { isShow } from '../../isShow'

export default defineComponent({
	setup() {
		const store = useStore()
		const state = reactive<{ circleUrl: string; touxiang: any; zhanghao: any; routesmatch: any }>({
			// https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png
			circleUrl: '@/assets/touxiang.png',
			touxiang: require('@/assets/touxiang.png'),
			zhanghao: '用户账号',
			// 存储当前路由match匹配规则 最终用来展示里面meta的title信息 （面包屑）
			routesmatch: []
		})
		const route = useRoute()
		const router = useRouter()
		onMounted(() => {
			if (localStorage.getItem('phone')) {
				state.zhanghao = localStorage.getItem('phone')
			}
		})

		watch(
			route,
			() => {
				// 面包屑需要遍历每个路由下match(匹配规则)里面meta(元信息)的title
				let title: any = []
				router.currentRoute.value.matched.forEach((item) => {
					// console.log(item.meta)
					title.push(item.meta)
				})
				state.routesmatch.length = 0
				state.routesmatch.push(...title)
			},
			{ deep: true, immediate: true }
		)
		const tuichu = () => {
			store.dispatch('user/loginout')
			router.push('/login')
		}
		const dianji = () => {
			isShow.value = !isShow.value
		}
		return { isShow, ...toRefs(state), tuichu, dianji }
	}
})
