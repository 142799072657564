import requests from "@/request/libraryindex";

// 获取分类
export const getclassify = () => requests({ url: "api/cloud/library/category", method: "GET" })

// 添加分类  name
export const addclassify = (data: any) => requests({ url: "api/cloud/library/category", method: "POST", data })

// 修改分类 id name
export const changeclassify = (data: any) => requests({ url: "api/cloud/library/category", method: "PATCH", data })

// 删除分类 id
export const deleteclassify = (data: any) => requests({ url: "api/cloud/library/category", method: "DELETE", data })

// 删除分类以及这个分类下的所有书籍 id
export const deleteclassifybook = (data: any) => requests({ url: "api/cloud/library/category_andbook", method: "DELETE", data })

// 获取所有分类以及分类下的所有书籍信息
export const getclassifythebook = () => requests({ url: "api/cloud/library/gacn", method: "GET" })