import { createStore } from 'vuex'
import user from './zhongtaimodules/user'   //导入中台模块
import school from './zhongtaimodules/school'
import libraryuser from './librarymodules/libraryuser'   //导入图书模块
import classify from './librarymodules/classify'
import book from './librarymodules/book'
import change from './librarymodules/change'
import library from './librarymodules/library'
import collaborateuser from './collaboratemodules/collaborateuser'  //导入合作伙伴模块

export default createStore({
	modules: {   //使用模块
		user,
		school,
		libraryuser,
		classify,
		book,
		change,
		library,
		collaborateuser
	}
})
