import { changebookmessage, deletebook, addallbooks } from '@/libraryapi/change'

export default {
    namespaced:true,    //namespaced:true代表该模块带有独立命名空间
		state:{        //否则，默认是处于全局命名空间，就和非模块化一样

    },
    mutations:{

    },
    actions:{
			// 更改书本信息
			async changebookmessage1(context:any,chaneform:any){
				let {id,bookName,author,translator,isbn	,publisher,	edition	,price,introduction,coverUrl,remain,categoryId}=chaneform
				let params={id,bookName,author,translator,isbn	,publisher,	edition	,price,introduction,coverUrl,remain,categoryId}
				// console.log(params);
				let result:any=await changebookmessage(params)
				if(result.code==0){
					// console.log(result);
					return 'ok'
				}else{
					return Promise.reject("更改当前书本信息失败")
				}
			},
			// 删除书本
			async deletebookclassify1(context:any,id:any){
				let result:any=await deletebook(id)
					if(result.code==0){
						// console.log(result);
						return 'ok'
					}else{
					return Promise.reject("删除书本失败")
				}
			},
			// 批量添加书本
			async addallbooks1(context:any,list:any){
				let listarr:any=[]
				let listjosn=JSON.parse(JSON.stringify(list))
				listarr.push(...listjosn)	
				let result:any=await addallbooks({list:listarr}) 
				if(result.code==0){
					// console.log(result);		
					return 'ok'
				}else{
					return Promise.reject("批量添加书本失败")
				}
			}
		},

}
