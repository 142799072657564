import requests from "@/request/index";
// 列出学校下的频道
export const getpingdao = (data: any) => requests({ url: "api/cloud/device/topic_school", method: "GET", params: data })


// 列出频道中的所有设备
export const getpingdaoshebei = (data: any) => requests({ url: "api/cloud/manage/topic/device", method: "POST", data })


// 查询未绑定学校的频道
export const getpingdaonoshebei = () => requests({ url: "api/cloud/manage/topic/unbind", method: "POST" })

// 指定ID 修改频道配置
export const changepindao = (data: any) => requests({ url: "api/cloud/manage/topic/unbind", method: "POST", data })

// 新增频道
export const addaddTopic = (data: any) => requests({ url: "api/cloud/device/addTopic", method: "POST", data })

// 获取所有的频道
export const gettopic_list = () => requests({ url: "api/cloud/device/topic_list", method: "GET" })

// 学校频道下设备列表
export const getschool_topic_device = (data: any) => requests({ url: "api/cloud/device/school_topic_device", method: "GET", params: data })