import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b3adcc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  style: {"width":"100%","display":"flex","justify-content":"space-between","align-items":"center"}
}
const _hoisted_6 = {
  key: 1,
  style: {"height":"91px"}
}
const _hoisted_7 = {
  key: 2,
  style: {"width":"100%","display":"flex","justify-content":"center","align-items":"center"}
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_middleelaside = _resolveComponent("middleelaside")!
  const _component_libraryelaside = _resolveComponent("libraryelaside")!
  const _component_collaboratelaside = _resolveComponent("collaboratelaside")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_middleelheader = _resolveComponent("middleelheader")!
  const _component_libraryelheader = _resolveComponent("libraryelheader")!
  const _component_collaboratelheader = _resolveComponent("collaboratelheader")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_middleelmain = _resolveComponent("middleelmain")!
  const _component_libraryelmain = _resolveComponent("libraryelmain")!
  const _component_collaboratelmain = _resolveComponent("collaboratelmain")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, null, {
          default: _withCtx(() => [
            (_ctx.type == 'middle')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_middleelaside)
                ]))
              : (_ctx.type == 'library')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_libraryelaside)
                  ]))
                : (_ctx.type == 'collaborate')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_collaboratelaside)
                    ]))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, { class: "header-and-main" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                (_ctx.type == 'middle')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_middleelheader)
                    ]))
                  : (_ctx.type == 'library')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_libraryelheader)
                      ]))
                    : (_ctx.type == 'collaborate')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_collaboratelheader)
                        ]))
                      : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                (_ctx.type == 'middle')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_middleelmain)
                    ]))
                  : (_ctx.type == 'library')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_libraryelmain)
                      ]))
                    : (_ctx.type == 'collaborate')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_collaboratelmain)
                        ]))
                      : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}