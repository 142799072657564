import axios from 'axios'
// const baseURL  =  'http://middle.icampus.ltd'
// 床架axios实例
// https://api.icampus.ltd
const requests = axios.create({
	baseURL: '/libraryapi',
	// baseURL: 'https://api.icampus.ltd',
	timeout: 5000,
	headers: {
		// application/json;charset=utf-8
		// application/json
		"Content-type": "application/json;charset=utf-8"
	}
})



//请求拦截(拦截下来接口)-->给权限接口携带token
requests.interceptors.request.use((config) => {
	// 给config.headers对象定义
	config.headers = config.headers || {}
	// 先判断是否本地存储了token
	if (localStorage.getItem("token")) {
		// config.headers.Bearer = localStorage.getItem("token") || ""
		config.headers.Authorization = 'Bearer ' + localStorage.getItem("token") || ""
	}
	// 一定要return-->不然请求不了接口
	return config
})

//响应拦截-->code=200成功
requests.interceptors.response.use((res) => {
	// 获取code,number是类型定义
	const code: number = res.data.code
	if (code != 0) {
		// 接口返回错误信息-->发送请求失败(返回错误信息)
		return Promise.reject(res.data)
	}
	// 成功 信息返回出去
	return res.data
}, (err) => {
	// 错误信息
	console.log(err)
})


export default requests