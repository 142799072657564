import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-567ef8b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","margin":"30px","justify-content":"space-around"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      onSizeChange: _ctx.handleSizeChange,
      onCurrentChange: _ctx.handleCurrentChange,
      "current-page": _ctx.currentPage,
      "page-sizes": [3, 5, 10],
      "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.page) = $event)),
      "page-size": _ctx.props.size,
      "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.props.size) = $event)),
      layout: " prev, pager, next, jumper ,-> , sizes ,total ",
      total: _ctx.props.total
    }, null, 8, ["onSizeChange", "onCurrentChange", "current-page", "page-size", "total"])
  ]))
}