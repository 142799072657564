import { getclassify, addclassify, changeclassify, deleteclassify, deleteclassifybook, getclassifythebook } from '@/libraryapi/classify'

export default {
	namespaced: true,    //namespaced:true代表该模块带有独立命名空间
	state: {        //否则，默认是处于全局命名空间，就和非模块化一样
		// 1.获取分类信息
		classify: [],
		// 2.获取所有分类信息以及所有分类下的书本数量
		classifythebook: [],
		// 表格加载动画
		loading: true
	},
	mutations: {
		GETCLASSIFY(state: any, classify: string[]) {
			state.classify = classify
			state.loading = false
		},
		GETLCASSIFYTHEBOOK(state: any, classifythebook: any[]) {
			state.classifythebook = classifythebook
		},
		// 将loading效果变成true
		LOADINGTRUE(state: any) {
			state.loading = true
		}
	},
	actions: {
		// 获取分类
		async getclassify1(context: any) {
			context.commit("LOADINGTRUE")
			let result: any = await getclassify()
			// console.log(result)
			if (result.code == 0) {
				context.commit("GETCLASSIFY", result.data)
				return 'ok'
			} else {
				return Promise.reject("获取分类数据失败")
			}
		},
		// 添加分类
		async addclassify1(context: any, name: any) {
			let result: any = await addclassify(name)
			// console.log(result);
			if (result.code == 0) {
				return 'ok'
			} else {
				return Promise.reject(new Error('添加分类失败'))
			}
		},
		// 删除分类
		async deleteclassify1(context: any, id: any) {
			let result: any = await deleteclassify(id)
			if (result.code == 0) {
				return 'ok'
			} else {
				return Promise.reject("删除分类失败")
			}
		},
		// 删除分类以及这个分类下的所有书籍
		async deleteclassify2(context: any, id: any) {
			let result: any = await deleteclassifybook(id)
			// console.log(result);
			if (result.code == 0) {
				return 'ok'
			} else {
				return Promise.reject("删除分类下的书本失败")
			}
		},
		// 修改分类
		async changeclassify1(context: any, idname: any) {
			let result: any = await changeclassify(idname)
			// console.log(result);
			if (result.code == 0) {
				return 'ok'
			} else {
				return Promise.reject("修改分类失败")
			}
		},
		// 获取所有分类以及分类下的所有书籍信息------------>这是echarts接口
		async getclassifythebook1(context: any) {
			let result: any = await getclassifythebook()
			// console.log(result);
			if (result.code == 0) {
				context.commit("GETLCASSIFYTHEBOOK", result.data.data)
				return 'ok'
			} else {
				return Promise.reject("获取数据失败")
			}
		}
	},
}
