import { getschool } from '@/api1/school'
import { gettopic_list } from '@/api1/pingdao'
export default {
	namespaced: true,    //namespaced:true代表该模块带有独立命名空间
	state: {             //否则，默认是处于全局命名空间，就和非模块化一样
		shcoolInfo: [],
		topicInfo: []
	},
	mutations: {
		GETSCHOOL(state: any, shcoolInfo: any) {
			state.shcoolInfo = shcoolInfo
		},
		GETTOPIC(state: any, topicInfo: any) {
			state.topicInfo = topicInfo
		}
	},
	actions: {
		async getschool1(context: any, userInfo: any) {
			let pagetiaojian
			console.log(userInfo);
			if (userInfo?.school != '') {
				pagetiaojian = {
					school: userInfo.school,
					page: 1,
					// 一页展示个数
					limit: 0,
					addr: ''
				}
			} else {
				pagetiaojian = {
					school: '',
					page: 1,
					// 一页展示个数
					limit: 0,
					addr: ''
				}
			}
			let result: any = await getschool(pagetiaojian)
			if (result?.code == 0) {
				// console.log(result);
				context.commit("GETSCHOOL", result.data.data)
			} else {
				Promise.reject("数据为0")
			}
		},
		async gettopic_list1(context: any) {
			let result: any = await gettopic_list()
			if (result?.code == 0) {
				context.commit("GETTOPIC", result.data.data)
			} else {
				Promise.reject("数据为0")
			}
		},
	},
	getters: {
		getidname: (state: any) => {
			let { shcoolInfo } = state
			let schoplidname: any = []
			shcoolInfo.forEach((item: any) => {
				schoplidname.push({ id: item.id, name: item.name })
			})
			return schoplidname
		}
	}
}
