
import { defineComponent } from 'vue'

import middleelaside from './modules/aside/index.vue'
import middleelheader from './modules/header/index.vue'
import middleelmain from './modules/main/index.vue'

import libraryelaside from './librarymodules/aside/index.vue'
import libraryelheader from './librarymodules/header/index.vue'
import libraryelmain from './librarymodules/main/index.vue'

import collaboratelaside from './collaboratemodules/aside/index.vue'
import collaboratelheader from './collaboratemodules/header/index.vue'
import collaboratelmain from './collaboratemodules/main/index.vue'

export default defineComponent({
	components: { middleelaside, middleelheader, middleelmain, libraryelaside, libraryelheader, libraryelmain, collaboratelaside, collaboratelheader, collaboratelmain },

	setup() {
		const type = localStorage.getItem('type')
		return { type }
	}
})
