import { login1 } from '@/api1/login'
// import shajs from 'sha.js'
export default {
	namespaced: true,    //namespaced:true代表该模块带有独立命名空间
	state: {             //否则，默认是处于全局命名空间，就和非模块化一样
		userInfo: []
	},
	mutations: {
		LOGIN(state: any, userInfo: any) {
			state.userInfo = userInfo
		}
	},
	actions: {
		// 中台后台管理系统登录操作
		async login12(context: any, userInfo: any) {
			let { phone, password } = userInfo
			let params = { phone, password }
			let result: any = await login1(params)
			// code有可能为空
			let code = result?.code
			if (code == 0) {
				localStorage.setItem("cookies", '111')
				localStorage.setItem("type", 'middle')
				return 'ok'
			} else {
				return Promise.reject('登录失败,请检查账号密码')
			}
		},
		// 中台后台管理系统退出登录
		loginout(context: any) {
			localStorage.removeItem("cookies")
			localStorage.removeItem("type")
		}
	},
	getters: {
	}
}
