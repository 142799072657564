import { login1 } from '@/collaborateapi/login'

export default {
	namespaced: true,    //namespaced:true代表该模块带有独立命名空间
	state: {             //否则，默认是处于全局命名空间，就和非模块化一样
		userInfo: ''
	},
	mutations: {
		LOGIN(state: any, userInfo: any) {
			state.userInfo = userInfo
		}
	},
	actions: {
		// 登录
		async login(context: any, userInfo: any) {
			// console.log(userInfo) , nonce1, nonce2, encrypt
			let { phone, password } = userInfo
			let params = {
				phone,
				password,
				// nonce1,
				// nonce2,
				// encrypt
			}
			let result: any = await login1(params)
			if (result.code == 0) {
				console.log(result.data);
				// 存储登录之后的token1(用于正常的接口访问需要携带的)和token2(用于后续如果token1过期了就会通过token2使用接口进行请求新的token1)
				localStorage.setItem("token1", JSON.stringify(result.data.token1))
				localStorage.setItem("token2", JSON.stringify(result.data.token2))
				localStorage.setItem("cookies", "333")
				localStorage.setItem("type", "collaborate")
				context.commit("LOGIN", result.data.partner)
				return 'ok'
			} else {
				return Promise.reject('登录失败,请检查账号密码')
			}
		},
		// 退出登录
		loginout() {
			localStorage.removeItem("cookies")
			localStorage.removeItem("type")
		}
	},
}
