
import { defineComponent, reactive, toRefs, watch, onMounted } from 'vue'

import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import SideItem from './SideItem.vue'
// 状态共用,放在同一个组件或一个文件中
import { isShow } from '../../isShow'
export default defineComponent({
	components: {
		SideItem
	},
	// name: 'aside',
	setup() {
		const banner12 = require('@/assets/logo1.png')
		const store = useStore()
		const route = useRoute()
		const router = useRouter()
		const state = reactive<{ routesmatch: any; allRoute: any }>({
			// 存储当前路由match匹配规则 最终用来展示里面meta的title信息 （面包屑）
			routesmatch: [],
			allRoute: []
		})
		onMounted(() => {
			// console.log(router.options.routes)
			state.allRoute = router.options.routes
		})
		watch(
			route,
			() => {
				// 面包屑需要遍历每个路由下match(匹配规则)里面meta(元信息)的title
				let title: any = []
				router.currentRoute.value.matched.forEach((item) => {
					// console.log(item.meta)
					title.push(item.meta)
				})
				state.routesmatch.length = 0
				state.routesmatch.push(...title)
			},
			{ deep: true, immediate: true }
		)
		return { isShow, ...toRefs(state), route, banner12 }
	}
})
