import { login1, changepassword } from '@/libraryapi/login'

export default {
	namespaced: true,    //namespaced:true代表该模块带有独立命名空间
	state: {             //否则，默认是处于全局命名空间，就和非模块化一样
		token: '',
		// 旧密码
		password: ''
	},
	mutations: {
		LOGIN(state: any, token: any) {
			state.token = token
		},
		savepassword(state: any, password: any) {
			state.password = password
		}
	},
	actions: {
		// 登录
		async login(context: any, userInfo: any) {
			let { phone, password } = userInfo
			let params = { phone, password }
			// console.log(userInfo);
			let result: any = await login1(params)
			// console.log(result);
			if (result.code == 0) {
				context.commit("savepassword", password)
				localStorage.setItem("token", result.data.token)
				localStorage.setItem("cookies", '222')
				localStorage.setItem("type", 'library')
				localStorage.setItem("login", JSON.stringify(params))
				context.commit("LOGIN", result.data.token)
				return 'ok'
			} else {
				return Promise.reject("登录信息有误,请重新填写")
			}
		},
		// 退出登录
		loginout() {
			localStorage.removeItem("token")
			localStorage.removeItem("cookies")
			localStorage.removeItem("type")
		},
		// 修改密码
		async changepassword1(context: any, params: any) {
			// console.log(params);		
			let result: any = await changepassword(params)
			if (result.code == 0) {
				// console.log(result.message);
				return result.message
			} else {
				// console.log(result.message);
				return Promise.reject(result.message)
			}

		}
	},
}
