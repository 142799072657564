import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { ref, computed } from 'vue'
import layout from '@/components/layout/index.vue'
// import { useStore } from 'vuex'
import store from '@/store/index'
// const store:any = useStore()
// const aa: any = ref(1)
const type = localStorage.getItem("type")

const redirecttypecomputed: any = computed(() => {
	if (type == 'middle') {
		return '/middle'
	} else if (type == 'library') {
		return '/library'
	} else if (type == 'collaborate') {
		return '/collaborate'
	}
})

const routes: Array<RouteRecordRaw> = [
	// 前方三选一 选择登录
	{
		path: '/index/login',
		name: 'indexlogin',
		component: () => import('../views/index/index.vue'),
		// 通过hidden来使得当前路由是否需要被展示
		meta: { hidden: false }
	},
	// 1.中台管理系统登录
	{
		path: '/middle/login',
		name: 'login',
		component: () => import('../views/login/login.vue'),
		// 通过hidden来使得当前路由是否需要被展示
		meta: { hidden: false }
	},
	// 2.图书管理系统登录
	{
		path: '/library/login',
		name: 'login2',
		component: () => import('../views/login/login2.vue'),
		// 通过hidden来使得当前路由是否需要被展示
		meta: { hidden: false }
	},
	// 3.合作伙伴管理系统登录
	{
		path: '/collaborate/login',
		name: 'login3',
		component: () => import('../views/login/login3.vue'),
		// 通过hidden来使得当前路由是否需要被展示
		meta: { hidden: false }
	},
	{
		path: '/',
		name: '',
		component: layout,
		redirect: redirecttypecomputed.value,
		children: [
			// 中台管理系统
			{
				path: '/middle',
				name: 'middle',
				meta: { title: '知了云校园开放平台', icon: 'Message' },
				redirect: '/middle/school',
				// component: layout,
				children: [
					{
						path: '/middle/school',
						name: 'school',
						// 元信息 是否展示子路由
						meta: { title: '所有学校', path: '/school', icon: 'Message' },
						component: () => import('../views/middlepage/school/index.vue'),
					},
					{
						path: '/middle/channel',
						name: 'channel',
						// 元信息 是否展示子路由
						meta: { title: '所有频道', path: '/channel', icon: 'Histogram' },
						component: () => import('../views/middlepage/channel/index.vue'),
					},
					{
						path: '/middle/nochannel',
						name: 'nochannel',
						// 元信息 是否展示子路由
						meta: { title: '未绑定学校频道', path: '/nochannel', icon: 'Histogram' },
						component: () => import('../views/middlepage/channel/nochannel/index.vue'),
					},
					{
						path: '/middle/equipment',
						name: 'equipment',
						// 元信息 是否展示子路由
						meta: { title: '绑定学校设备', path: '/equipment', icon: 'Message' },
						component: () => import('../views/middlepage/equipment/index.vue'),
					},
					{
						path: '/middle/noequipment',
						name: 'noequipment',
						// 元信息 是否展示子路由
						meta: { title: '未绑定学校设备', path: '/noequipment', icon: 'Message' },
						component: () => import('../views/middlepage/equipment/noequipment/index.vue'),
					},
					{
						path: '/middle/zhanshi',
						name: 'zhanshi',
						// 元信息 是否展示子路由
						meta: { title: '所有设备', path: '/zhanshi', icon: 'Message' },
						component: () => import('../views/middlepage/zhanshi/index.vue'),
					}
				]
			},
			// 图书管理系统
			{
				path: '/library',
				name: 'library',
				meta: { title: '图书管理系统', icon: 'Message' },
				redirect: '/library/analysis',
				children: [
					// analysis
					{
						path: '/library/book',
						name: 'book',
						// 元信息 是否展示子路由
						meta: { title: '图书明细', path: '/book', icon: 'Message' },
						component: () => import('../views/librarypage/book/index.vue'),
					},
					{
						path: '/library/analysis',
						name: 'analysis',
						// 元信息 是否展示子路由
						meta: { title: '数据分析', path: '/analysis', icon: 'Message' },
						component: () => import('../views/librarypage/book/analysis.vue'),
					},
					{
						path: '/library/classify',
						name: 'classify',
						// 元信息 是否展示子路由
						meta: { title: '图书分类管理', path: '/classify', icon: 'Message' },
						component: () => import('../views/librarypage/classify/index.vue'),
					},
					{
						path: '/library/library',
						name: 'library',
						// 元信息 是否展示子路由
						meta: { title: '图书馆信息', path: '/library', icon: 'Message' },
						component: () => import('../views/librarypage/library/index.vue'),
					},
				]
			},
			// 合作伙伴管理系统
			{
				path: '/collaborate',
				name: 'collaborate',
				meta: { title: '合作伙伴管理系统', icon: 'Message' },
				redirect: '/collaborate/home',
				children: [
					{
						path: '/collaborate/home',
						name: 'home',
						// 元信息 是否展示子路由
						meta: { title: '合作伙伴首页', path: '/home', icon: 'Message' },
						component: () => import('../views/collaboratepage/home/home.vue'),
					},
					{
						path: '/collaborate/adcontract',
						name: 'adcontract',
						// 元信息 是否展示子路由
						meta: { title: '广告合约', path: '/adcontract', icon: 'Message' },
						component: () => import('../views/collaboratepage/Adcontract/Adcontractlist.vue'),
					},
					{
						path: '/collaborate/service',
						name: 'service',
						// 元信息 是否展示子路由
						meta: { title: '服务管理', path: '/service', icon: 'Message' },
						component: () => import('../views/collaboratepage/service/servicelist.vue'),
					},
					{
						path: '/collaborate/rnauthentication',
						name: 'rnauthentication',
						// 元信息 是否展示子路由
						meta: { title: '实名认证', path: '/rnauthentication', icon: 'Message' },
						component: () => import('../views/collaboratepage/Rnauthentication/Rnauthenticationlist.vue'),
					},
					{
						path: '/collaborate/payment',
						name: 'payment',
						// 元信息 是否展示子路由
						meta: { title: '支付记录', path: '/payment', icon: 'Message' },
						component: () => import('../views/collaboratepage/payment/paymentlist.vue'),
					},
					{
						path: '/collaborate/advertisement',
						name: 'advertisement',
						// 元信息 是否展示子路由
						meta: { title: '广告位', path: '/advertisement', icon: 'Message' },
						component: () => import('../views/collaboratepage/getad/getadlist.vue'),
					},
					{
						path: '/collaborate/wx',
						name: 'wx',
						// 元信息 是否展示子路由
						meta: { title: '微信待签约特约商户', path: '/wx', icon: 'Message' },
						component: () => import('../views/collaboratepage/wx/wxlist.vue'),
					},
				]
			},

		]

	},
	// 用户瞎打一个地址 就显示404页
	{
		// 匹配字符
		path: '/:xxx(.*)*',
		name: 'ErrorPage',
		component: () => import("@/views/ErrorPage.vue"),
	},

]

// const routes: Array<RouteRecordRaw> = [
// 	{
// 		path: '/login',
// 		name: 'login',
// 		component: () => import('../views/login/login.vue'),
// 		// 通过hidden来使得当前路由是否需要被展示
// 		// meta:{hidden:false}
// 	},
// 	{
// 		path: '/',
// 		name: 'home',
// 		redirect: '/home',
// 		component: layout,
// 		meta: { hidden: true },
// 		// meta:{title:"首页",path:'/'},
// 		children: [
// 			{
// 				path: '/home',
// 				name: 'home',
// 				meta: { title: '中台首页', icon: 'Message' },
// 				component: () => import('../views/zhongtaihome/index2.vue')
// 			},
// 		]
// 	},
// 	{
// 		path: '/list',
// 		name: 'list',
// 		redirect: '/school',
// 		component: layout,
// 		// 元信息 是否展示子路由
// 		meta: { title: '学校', icon: 'Message', hidden: false },
// 		children: [
// 			{
// 				path: '/school',
// 				name: 'school',
// 				// 元信息 是否展示子路由
// 				meta: { title: '列出学校', path: '/school', icon: 'Message' },
// 				component: () => import('../views/list/index.vue'),
// 			}
// 		]
// 	},
// 	{
// 		path: '/list2',
// 		name: 'list2',
// 		component: layout,
// 		redirect: '/channel',
// 		// 元信息 是否展示子路由
// 		meta: { title: '频道', hidden: false, icon: 'Histogram' },
// 		children: [
// 			{
// 				path: '/channel',
// 				name: 'channel',
// 				// 元信息 是否展示子路由
// 				meta: { title: '列出频道', path: '/channel', icon: 'Message' },
// 				component: () => import('../views/list2/index.vue'),
// 			}
// 		]
// 	},
// 	{
// 		path: '/list4',
// 		name: 'list4',
// 		component: layout,
// 		redirect: '/equipment',
// 		// 元信息 是否展示子路由
// 		meta: { title: '设备', icon: 'Message', hidden: false },
// 		children: [
// 			{
// 				path: '/equipment',
// 				name: 'equipment',
// 				// 元信息 是否展示子路由
// 				meta: { title: '列出设备', path: '/equipment', icon: 'Message', hidden: false },
// 				component: () => import('../views/list3/index.vue'),
// 			}
// 		]
// 	},
// 	// 用户瞎打一个地址 就显示404页
// 	{
// 		// 匹配字符
// 		path: '/:xxx(.*)*',
// 		name: 'ErrorPage',
// 		component: () => import("@/views/ErrorPage.vue"),
// 		meta: { hidden: false }
// 	},
// ]


const router = createRouter({
	// process.env.BASE_URL
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	let cookies = localStorage.getItem("cookies")
	const type = localStorage.getItem("type")
	// 1.确保有cookies才能够跳转到首页
	// 2.没cookies的话不能跳转到首页,只能跳转到登录页
	// 登录成功




	// 如果当前登录的是中台后台管理系统 
	if (cookies == '111' && type == 'middle') {   //保证coookies都有和type类型时middle的情况下 , 才能够正常跳转
		if (to.path == '/middle/login' || to.path == '/index/login') {
			//跳转到首页
			next('/middle/school')
			// next(false)
		} else {
			// 中台管理系统下 每次路由跳转都会发起请求获取学校信息
			store.dispatch('school/getschool1', {
				school: ''
			})
			store.dispatch('school/gettopic_list1')
			// 登录成功之后 如果跳转到其他页面 ---->并且不是middle的话就会跳转到首页
			if (to.path.split('/')[1] != 'middle') {
				next('/middle/school')
			} else {
				// 如果是middle的 就自由同行
				next()
			}
		}
	}

	// // 如果当前登录的是图书后台管理系统 
	if (cookies == '222' && type == 'library') {
		if (to.path == '/library/login' || to.path == '/index/login') {
			//跳转到首页
			next('/library/book')
		} else {
			if (to.path.split('/')[1] != 'library') {
				next('/library/book')
			} else {
				// 如果是library的 就自由同行
				next()
			}
		}
	}

	// // 如果当前登录的是合作伙伴后台管理系统 
	if (cookies == '333' && type == 'collaborate') {
		if (to.path == '/collaborate/login' || to.path == '/index/login') {
			//跳转到首页
			next('/collaborate/home')
		} else {
			if (to.path.split('/')[1] != 'collaborate') {
				next('/collaborate/home')
			} else {
				// 如果是collaborate的 就自由同行
				next()
			}
		}
	}
	// 中台，图书馆，合作伙伴都登录不成功--->通过监听
	// cookies != '111' || type != 'middle'
	if (!cookies || !type) {  //如果没有cookies且type类型不等于空的时候,就直接跳转到login--->防止页面跳转的时候进行路由跳转错误
		// 1.如果去三个登录的话,可以同行
		if (to.path == '/middle/login') {
			next()
		} else if (to.path == '/library/login') {
			next()
		} else if (to.path == '/collaborate/login') {
			next()
		} else if (to.path == '/index/login') {
			next()
			// 如果用户在未登录的情况下 想要跳转根路径携带middle的,就跳转到middle下的登录
		} else if (from.path.split('/')[1] == 'middle') {
			next('/middle/login')
		} else if (from.path.split('/')[1] == 'library') {
			next('/library/login')
		} else if (from.path.split('/')[1] == 'collaborate') {
			next('/collaborate/login')
		}
		else {
			// 2.如果去其他页面---->其他跟上面无关的的话,强制停留在当前页,就是登录页
			next('/middle/login')
			// next('/index/login')
		}
	}

})

// // 动态添加路由 因为数据是异步的
// router.addRoute({
// 	path: '/list',
// 	name: 'list',
// 	redirect: '/school',
// 	component: layout,
// 	// 元信息 是否展示子路由
// 	meta: { title: '学校', icon: 'Message', hidden: false },
// 	children: [
// 		{
// 			path: '/school',
// 			name: 'school',
// 			// 元信息 是否展示子路由
// 			meta: { title: '列出学校', path: '/school', icon: 'Message' },
// 			component: () => import('../views/list/index.vue'),
// 		}
// 	]
// })

// router.addRoute({
// 	path: '/list2',
// 	name: 'list2',
// 	component: layout,
// 	redirect: '/channel',
// 	// 元信息 是否展示子路由
// 	meta: { title: '频道', hidden: false, icon: 'Histogram' },
// 	children: [
// 		{
// 			path: '/channel',
// 			name: 'channel',
// 			// 元信息 是否展示子路由
// 			meta: { title: '列出频道', path: '/channel', icon: 'Message' },
// 			component: () => import('../views/list2/index.vue'),
// 		}
// 	]
// })

// router.addRoute({
// 	path: '/list4',
// 	name: 'list4',
// 	component: layout,
// 	redirect: '/equipment',
// 	// 元信息 是否展示子路由
// 	meta: { title: '设备', icon: 'Message', hidden: false },
// 	children: [
// 		{
// 			path: '/equipment',
// 			name: 'equipment',
// 			// 元信息 是否展示子路由
// 			meta: { title: '列出设备', path: '/equipment', icon: 'Message', hidden: false },
// 			component: () => import('../views/list3/index.vue'),
// 		}
// 	]
// })









export default router
