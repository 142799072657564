import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e0f31a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-dropdown-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Expand = _resolveComponent("Expand")!
  const _component_Fold = _resolveComponent("Fold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_icon, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dianji())),
      size: 20
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_Expand, null, null, 512), [
          [_vShow, _ctx.isShow]
        ]),
        _withDirectives(_createVNode(_component_Fold, null, null, 512), [
          [_vShow, !_ctx.isShow]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_breadcrumb, { separator: "/" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routesmatch, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: index }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_el_dropdown, null, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.zhanghao), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, {
              divided: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tuichu()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("退出")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createVNode(_component_el_avatar, {
            size: 30,
            src: _ctx.touxiang
          }, null, 8, ["src"]),
          _createVNode(_component_el_icon, { class: "el-icon--right" }, {
            default: _withCtx(() => [
              _createVNode(_component_arrow_down)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ], 64))
}