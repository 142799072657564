
import { defineComponent, defineEmits, defineProps } from 'vue'
export default defineComponent({
	name: 'App',
	props: {
		// 当前页
		currentPage: {
			type: [String, Number],
			default: 1,
			required: true
		},
		// 一页显示数量
		size: {
			type: [String, Number],
			default: 3,
			required: true
		},
		// 数据总量
		total: {
			type: [String, Number],
			default: 10,
			required: true
		}
	},

	setup(props, { attrs, slots, emit }) {
		// })
		//每页显示条数改变
		const handleSizeChange = (val: any) => {
			console.log(`每页 ${val} 条`)
			emit('changelimit', val)
		}

		//页数改变
		const handleCurrentChange = (val: any) => {
			console.log(`当前页: ${val}`)
			emit('changecurrentPage', val)
		}
		return { handleSizeChange, handleCurrentChange, props }
	}
})
