import axios from 'axios'
import { getrenew } from '@/collaborateapi/login'
import { resultProps } from 'element-plus'
// const baseURL  =  'http://middle.icampus.ltd'
// 床架axios实例
// https://api.icampus.ltd
const requests = axios.create({
	baseURL: '/collaborateapi',
	// baseURL: 'https://api.icampus.ltd',
	timeout: 5000,
	headers: {
		// application/json;charset=utf-8
		// application/json
		"Content-type": "application/json;charset=utf-8"
	}
})



//请求拦截(拦截下来接口)-->给权限接口携带token
requests.interceptors.request.use((config) => {
	// 给config.headers对象定义
	config.headers = config.headers || {}
	// 先判断是否本地存储了token
	if (localStorage.getItem("token1")) {
		// config.headers.Bearer = localStorage.getItem("token") || ""
		config.headers.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem("token1") || '') || ""
	}
	// 一定要return-->不然请求不了接口
	return config
})

//响应拦截-->code=200成功
requests.interceptors.response.use(async (res) => {
	// 获取code,number是类型定义
	const code: number = res.data.code
	// if (code != 0) {
	// 	接口返回错误信息-->发送请求失败(返回错误信息)
	// 	return Promise.reject(res.data)
	// }

	//实现只执行函数一次
	let flag: any
	if (code != 0) {
		flag = true
		console.log(code);
		let token2 = JSON.parse(localStorage.getItem("token2") || "")
		if (flag) {
			let result: any = await getrenew({ token2 })
			if (result.code == 0) {
				console.log(result);
				localStorage.removeItem("token1")
				localStorage.removeItem("token2")
				localStorage.setItem("token1", JSON.stringify(result.data.token1))
				localStorage.setItem("token2", JSON.stringify(result.data.token2))
				flag = false
				// 成功 信息返回出去
			}
		}
	}

	// 成功 信息返回出去
	return res.data
}, (err) => {
	// 错误信息
	console.log(err)
})


export default requests