import requests from "@/request/libraryindex";

// 登录
export const login1=(data:any)=>requests({url: "api/cloud/library/admin",method: "POST",data})

// 修改密码 传旧密码和新密码
// export const changepassword=(data:any)=>requests({url: "api/cloud/library/changepass",method: "patch",data})

export const changepassword = (data: any) => {
  return requests({
    method: "PATCH",
    url: "api/cloud/library/changepass",
    data,
  });
};