import { getallbooks, getclassifybooks, searchbook, classifysearchbook } from '@/libraryapi/book'

export default {
	namespaced: true,    //namespaced:true代表该模块带有独立命名空间
	state: {        //否则，默认是处于全局命名空间，就和非模块化一样
		allbooks: [],
		total: 0,
		// 1当前分页是全部数据 2当前是分类的数据 3是搜索书名的数据 4当前是查询分类+书名的数据  
		index: 0,
		// 表格加载动画
		loading: true
	},
	mutations: {
		// 获取全部数据
		GETALLBOOKS(state: any, allbooks: any[]) {
			state.allbooks = allbooks
			state.loading = false
		},
		// 获取全部数据的total
		GETALLBOOKSNUM(state: any, total: number) {
			state.total = total
		},
		// 1当前分页是全部数据 2当前是分类的数据 3当前是查询分类+书名的数据
		CHANGEINDEX(state: any, index: number) {
			state.index = index
			state.loading = false
		},
		// 将loading效果变成true
		LOADINGTRUE(state: any) {
			state.loading = true
		}
	},
	actions: {
		// 获取全部图书数据
		async getallbooks1(context: any, bookparams: any) {
			context.commit("LOADINGTRUE")
			let result: any = await getallbooks(bookparams)
			// console.log('全',result);
			if (result.code == 0) {
				context.commit("CHANGEINDEX", 1)
				context.commit("GETALLBOOKSNUM", result.data.total)
				context.commit("GETALLBOOKS", result.data.Data)
				return 'ok'
			} else {
				return Promise.reject("获取图书失败")
			}
		},
		// 获取某个分类下的书本列表
		async getclassifybooks1(context: any, bookclassifyparams: any) {
			context.commit("LOADINGTRUE")
			let result: any = await getclassifybooks(bookclassifyparams)
			if (result.code == 0) {
				// console.log(result);
				context.commit("CHANGEINDEX", 2)
				context.commit("GETALLBOOKSNUM", result.data.total)
				context.commit("GETALLBOOKS", result.data.Data)
				return 'ok'
			} else {
				return Promise.reject("获取图书失败")
			}
		},
		// 获取搜索书本下的数据--->这是全部数据下的
		async getsearchbook(context: any, searchbookparams: any) {
			context.commit("LOADINGTRUE")
			let result: any = await searchbook(searchbookparams)
			if (result.code == 0) {
				context.commit("CHANGEINDEX", 3)
				context.commit("GETALLBOOKSNUM", result.data.total)
				context.commit("GETALLBOOKS", result.data.Data)
				return 'ok'
			} else {
				return Promise.reject("查询图书失败")
			}
		},
		// 获取分类+书名下的数据--->这是用户获取了分类书本的情况下
		async getclassifysearchbook(context: any, classifysearchbookparams: any) {
			context.commit("LOADINGTRUE")
			let result: any = await classifysearchbook(classifysearchbookparams)
			if (result.code == 0) {
				context.commit("CHANGEINDEX", 4)
				context.commit("GETALLBOOKSNUM", result.data.total)
				context.commit("GETALLBOOKS", result.data.Data)
				return 'ok'
			} else {
				return Promise.reject("获取图书失败")
			}
		}
	},
}
