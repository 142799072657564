import requests from "@/request/libraryindex";

// 更改书本信息
export const changebookmessage=(data:any)=>requests({url: "api/cloud/library/book",method: "PATCH",data})

// 删除书本
export const deletebook=(data:any)=>requests({url: "api/cloud/library/book",method: "DELETE",data}) 


// 批量添加书本
export const addallbooks=(data:any)=>requests({url: "api/cloud/library/book",method: "POST",data}) 