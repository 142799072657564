import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// import 'default-passive-events'

const app = createApp(App)
// 全局注册图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}

// 注册全局组件------------2.分页器
import Pagination from '@/views/Pagination/index.vue'
app.component("Pagination", Pagination)


app.use(store).use(router).use(ElementPlus).mount('#app')
