import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideItem = _resolveComponent("SideItem", true)!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allRoute, (item, index) => {
    return (_openBlock(), _createElementBlock("div", { key: index }, [
      (item?.meta?.hidden && item?.children && item?.path != '/login' && item?.path != '/:xxx(.*)*')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_SideItem, {
              allRoute: item?.children
            }, null, 8, ["allRoute"])
          ]))
        : _createCommentVNode("", true),
      (!item?.meta?.hidden && item?.path != '/login' && item?.path != '/:xxx(.*)*')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (item?.children)
              ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                  key: 0,
                  index: item?.path
                }, {
                  title: _withCtx(() => [
                    _createVNode(_component_el_icon, { size: 20 }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item?.meta?.icon || ''), { style: {"width":"20px","height":"20px"} }))
                      ]),
                      _: 2
                    }, 1024),
                    _withDirectives(_createElementVNode("h1", { style: {"font-size":"15px","color":"var(--el-text-color-primary)","font-weight":"bold"} }, _toDisplayString(item?.meta?.title), 513), [
                      [_vShow, !_ctx.isShow]
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_SideItem, {
                      allRoute: item?.children
                    }, null, 8, ["allRoute"])
                  ]),
                  _: 2
                }, 1032, ["index"]))
              : _createCommentVNode("", true),
            (!item?.children)
              ? (_openBlock(), _createBlock(_component_el_menu_item, {
                  key: 1,
                  index: item?.path
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, { size: 20 }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item?.meta?.icon || ''), { style: {"width":"20px","height":"20px"} }))
                      ]),
                      _: 2
                    }, 1024),
                    _withDirectives(_createElementVNode("h1", {
                      slot: "title",
                      style: {"font-size":"14px","color":"var(--el-text-color-primary)","font-weight":"bold"}
                    }, _toDisplayString(item?.meta?.title), 513), [
                      [_vShow, !_ctx.isShow]
                    ])
                  ]),
                  _: 2
                }, 1032, ["index"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}