import requests from "@/request/libraryindex";

export const getallbooks1 = (data: any) => requests({ url: "url", method: "GET", params: data })






// 获取所有书本列表  传参 num（当前页数）和size（一页展示多少个）
export const getallbooks = (data: any) => requests({ url: "api/cloud/library/book_all", method: "GET", params: data })

// 获取分类下的书本 传参 category_id是分类id num（当前页数）和size（一页展示多少个）
export const getclassifybooks = (data: any) => requests({ url: "api/cloud/library/book_category", method: "GET", params: data })

// 全部数据下搜索书本 name书名  num（当前页数）和size（一页展示多少个）
export const searchbook = (data: any) => requests({ url: "api/cloud/library/scan_book", method: "GET", params: data })

// 根据分类+书名搜索书本  name书名  num（当前页数）和size（一页展示多少个） category_id是分类id Data:当前分类下的所有书本
export const classifysearchbook = (data: any) => requests({ url: "api/cloud/library/scan_book_cate", method: "GET", params: data })